@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  margin: 0;
  padding: 0;
  border:0 !important;

}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
